import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from "sweetalert2";
import { LocalStorageService } from '../_shared/storage';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private localStorageAs: LocalStorageService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.url.includes('/admin/login')
      && !req.url.includes('/crear-afiche')
      && !req.url.includes('/crear-banner')
      && !req.url.includes('/crear-comprobante')
      && !req.url.includes('/crear-foto-perfil')
      && !req.url.includes('/crear-comprobante-devolucion')
      && !req.url.includes('/crear-imagen-adicional')
      && !req.url.includes('/crear-cotizacion')
      && !req.url.includes('/crear-imagen')
      && !req.url.includes('/crear-geojson')
      && !req.url.includes('/archivo')
    ) {
      const dataUser = JSON.parse(this.localStorageAs.get('dataUser'));
      if (dataUser) {
        const id = dataUser.id;
        const token = dataUser.token;
        req = req.clone({
          setHeaders: {
            adminToken: `Bearer ${token}`,
            'iduser': `${id}`
            // 'x-auth-token': environment.api_token,
            // 'PRIVATE-TOKEN': environment.api_token_gitlab,
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Methods': 'GET',
          }
        });
      }
    }

    if (
      req.url.includes('/crear-afiche')
      || req.url.includes('/crear-banner')
      || req.url.includes('/crear-comprobante')
      || req.url.includes('/crear-foto-perfil')
      || req.url.includes('/crear-comprobante-devolucion')
      || req.url.includes('/crear-imagen-adicional')
      || req.url.includes('/crear-cotizacion')
      || req.url.includes('/crear-imagen')
      || req.url.includes('/crear-geojson')
      || req.url.includes('/archivo')


    ) {
      const dataUser = JSON.parse(this.localStorageAs.get('dataUser'));

      if (dataUser) {
        const token = dataUser.token;
        const id = dataUser.id;

        req = req.clone({
          setHeaders: {
            // 'Content-Type': 'multipart/form-data',
            adminToken: `Bearer ${token}`,
            'iduser': `${id}`,
            // 'x-auth-token': environment.api_token,
            // 'PRIVATE-TOKEN': environment.api_token_gitlab,
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Credentials': 'true',
            // 'Access-Control-Allow-Methods': 'GET',
          }
        });

      }
    }




    return next.handle(req).pipe(

      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 || err.status === 403) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Sin autorización o sesión ha caducado.',
            });
            this.router.navigate(['/auth']);
          }
          if (err.status === 429) {
            // this.router.navigate(['/auth']);
          }
          if (err.status === 500) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'A ocurrido un error, vuelve a intentarlo en unos minutos.',
            })
          }

        }
      })
    );
  }
}
