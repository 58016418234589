import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './_shared/storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'bikerace-dashboard';
  public userDataComplete = null;
  public dataUser = null;

  constructor(
    private router: Router,
    private localStorageAs: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.userDataComplete = JSON.parse(this.localStorageAs.get('dataUserComplete'));

    if (!this.userDataComplete) {
      this.router.navigate(['/auth/login']);
    }
  }
}
