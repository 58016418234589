import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class RiderService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }

  obtenerRiders() {
    return this.http.get<any>(`${environment.api}/rider`);
  }

  obtenerRidersPositionPorOrganizadorConLimite(idOrganizador: number, take: number) {
    return this.http.get<any>(`${environment.api}/rider/organizador/${idOrganizador}/position/take/${take}`);
  }

  obtenerIpsRiders(eventoId:number) {
    return this.http.get<any>(`${environment.api}/rider/ips/evento/${eventoId}`);
  }

  obtenerRidersSimplifyPorEvento(eventoId: number) {
    return this.http.get<any>(`${environment.api}/rider/simplify/evento/${eventoId}`);
  }

  obtenerRidersPorEventoYCategoriaYEstadoYDesafio(idEvento: number, idCategoria, estado: string, desafio: string) {
    return this.http.get<any>(`${environment.api}/rider/evento/${idEvento}/categoria/${idCategoria}/estado/${estado}/desafio/${desafio}`);
  }

  obtenerRidersPorEventoYCategoriaYOrganizadorYDesafio(idEvento: number, idOrganizador: number, idCategoria: number, desafio: string, estado: string) {
    return this.http.get<any>(`${environment.api}/rider/organizador/${idOrganizador}/evento/${idEvento}/categoria/${idCategoria}/desafio/${desafio}/estado/${estado}`);
  }

  obtenerRidersPorOrganizadorConLimite(idOrganizador: number, take: number) {
    return this.http.get<any>(`${environment.api}/rider/organizador/${idOrganizador}/take/${take}`);
  }

  obtenerRidersConLimite(take: number) {
    return this.http.get<any>(`${environment.api}/rider/take/${take}`);
  }

  obtenerRidersPorTransaccion(transaccionId: number) {
    return this.http.get<any>(`${environment.api}/rider/transaccion/${transaccionId}`);
  }

  obtenerRidersPorOrganizador(idOrganizador: number) {
    return this.http.get<any>(`${environment.api}/rider/organizador/${idOrganizador}`);
  }

  obtenerRiderPorId(riderId: number) {
    return this.http.get(`${environment.api}/rider/${riderId}`);
  }

  obteneresRiderPorRut(rut: string) {
    return this.http.get(`${environment.api}/rider/many/rut/${rut}`);
  }

  obtenerRiderPorIdYUsuarioOrganizador(riderId: number, idOrganizador: number) {
    return this.http.get(`${environment.api}/rider/${riderId}/organizador/${idOrganizador}`);
  }

  crearRiderInvitacion(form: any) {
    return this.http.post(`${environment.api}/rider/invitacion`, form);
  }

  traspasarRiderExistente(form: any) {
    return this.http.post(`${environment.api}/rider/traspasar-rider-existente`, form);
  }

  verificarRiders(form: any) {
    return this.http.post(`${environment.api}/rider/verificar-riders`, form);
  }

  traspasarRiderNuevo(form: any) {
    return this.http.post(`${environment.api}/rider/traspasar-nuevo-rider`, form);
  }

  actualizarRider(riderId: number, form: any) {
    return this.http.patch(`${environment.api}/rider/${riderId}`, form);
  }

  actualizarDor(riderId: number, form: any) {
    return this.http.patch(`${environment.api}/rider/update-dor/${riderId}`, form);
  }

  actualizarTag(riderId: number, form: any) {
    return this.http.patch(`${environment.api}/rider/update-tag/${riderId}`, form);
  }

  eliminarRider(riderId: number) {
    return this.http.delete(`${environment.api}/rider/${riderId}`);
  }

  obtenerEstadisticaRidersPorEventoGraficoPorUsuarioConLimite(take: number, idOrganizador: number) {
    return this.http.get<any>(`${environment.api}/rider/grafico/organizador/${idOrganizador}/take/${take}`);
  }

  obtenerEstadisticaRidersPorEventoGraficoConLimite(take: number) {
    return this.http.get<any>(`${environment.api}/rider/grafico/take/${take}`);
  }

  actualizarTagDorImport(form: any) {
    return this.http.post(`${environment.api}/rider/import-tag-dor`, form);
  }

  obtenerDorsalesOcupadas(eventoId: number) {
    return this.http.get<any>(`${environment.api}/rider/dorsales-ocupadas/evento/${eventoId}`);
  }

}
