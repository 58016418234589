import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/_shared/storage';
import { ThemeOptions } from '../../theme-options';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  public menus = [];

  constructor(public globals: ThemeOptions, private activatedRoute: ActivatedRoute,
    private localStorageAs: LocalStorageService,

  ) { }

  private newInnerWidth: number;
  private innerWidth: number;
  public activeId;

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
    this.globals.sidebarHover = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  sidebarHoverMouseOut() {
    this.globals.sidebarHover = false;
  }

  sidebarHoverMouseIn() {
    this.globals.sidebarHover = true;
  }


  ngOnInit() {
    this.menus = JSON.parse(this.localStorageAs.get('menus'));
    this.menus = this.menus.sort((a, b) => (a.titulo < b.titulo) ? -1 : 1);

    this.menus.forEach((menu) => {
      menu.subMenus = menu.subMenus.sort((a, b) => (a.titulo < b.titulo) ? -1 : 1)
    });

    if (this.filterMenu('BIKERACE').length > 0) {
      this.activeId = 'Bienvenido_BIKERACE';
    } else {
      if (this.filterMenu('RIDERTICKET').length > 0) {
        this.activeId = 'Bienvenido_RIDERTICKET';
      }
    }

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter = this.activatedRoute.snapshot.firstChild.routeConfig.path;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }


  filterMenu(categoria) {
    return this.menus.filter((menu) => menu.categoria === categoria);
  }
}
