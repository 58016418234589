import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { LocalStorageService } from '../_shared/storage';

@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageAs: LocalStorageService,
  ) { }

  login(formLogin: any) {
    return this.http.post<any>(`${environment.api}/admin/login`, formLogin);
  }

  logout() {
    window.localStorage.clear();
    this.localStorageAs.clear();
    this.router.navigate(['/auth/login']);
  }

}
