import { Component, HostBinding } from '@angular/core';
import { LocalStorageService } from 'src/app/_shared/storage';
import { ThemeOptions } from '../../theme-options';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  public logo = '';
  public userDataComplete = null;

  constructor(public globals: ThemeOptions, private localStorageAs: LocalStorageService,
  ) {
    this.userDataComplete = JSON.parse(this.localStorageAs.get('dataUserComplete'));

  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;


  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

}
