import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class DevolucionService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }


  obtenerDevolucionesPorEventoYEstado(eventoId: number, estado: string) {
    return this.http.get<any>(`${environment.api}/devolucion/evento/${eventoId}/estado/${estado}`);
  }

  obtenerDevolucion(id: number) {
    return this.http.get<any>(`${environment.api}/devolucion/${id}`);
  }

  obtenerMontoDevolucion() {
    return this.http.get<any>(`${environment.api}/devolucion/monto`);
  }


  obtenerCantidadDevolucion() {
    return this.http.get<any>(`${environment.api}/devolucion/cantidad`);
  }

  obtenerCuentaAgregadaABanco(bancoId: number, tipoCuentaId: number, cuenta: string) {
    return this.http.get<any>(`${environment.api}/devolucion/banco/${bancoId}/tipo-cuenta/${tipoCuentaId}/cuenta/${cuenta}`);
  }

  notificarCreacionBanco(id: number) {
    return this.http.post<any>(`${environment.api}/devolucion/notificar-creacion-banco`, {'id': id});
  }

  obtenerDevolucionesConLimite(take: number) {
    return this.http.get<any>(`${environment.api}/devolucion/take/${take}`);
  }


  crearDevolucion(form: any) {
    return this.http.post(`${environment.api}/devolucion`, form);
  }

  actualizarDevolucion(devolucionId: number, form: any) {
    return this.http.patch(`${environment.api}/devolucion/${devolucionId}`, form);
  }

  crearComprobanteDevolucion(corredorId: number, file: any) {
    return this.http.post(`${environment.api}/devolucion/corredor/${corredorId}/crear-comprobante-devolucion`, file);
  }
}
